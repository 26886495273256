import {useRouter} from "next/router";

const navigation = {
    main_en: [
        { name: 'Imprint', href: 'https://rescribe.ai/impressum' },
        { name: 'Data Protection', href: 'https://rescribe.ai/datenschutz' },
        { name: 'Terms of Service', href: 'https://rescribe.ai/agb' },
    ],
    main_de:[
        { name: 'Impressum', href: 'https://rescribe.ai/impressum' },
        { name: 'Datenschutz', href: 'https://rescribe.ai/datenschutz' },
        { name: 'Allgemeine Geschäftsbedingungen', href: 'https://rescribe.ai/agb' },
    ]
}

export default function Footer() {

    const locale = useRouter().locale

    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {locale==="de"?(navigation.main_de.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                {item.name}
                            </a>
                        </div>
                    ))):navigation.main_en.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="mt-8 text-center text-base text-gray-400">&copy; 2022 ReScribe. All rights reserved.</p>
            </div>
        </footer>
    )
}
