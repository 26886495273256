import {i18n} from "next-i18next";
import {useRouter} from "next/router";
import Link from "next/link";
import {GlobeAltIcon} from "@heroicons/react/24/outline";
import Image from "next/image";


export default function LanguageSwitchMobile(){

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const router = useRouter();
    const locale = router.locale;



    return(
        <div className={''}>
            <Link className={'w-[23px] h-[23px]'}
            locale={locale==="de"?"en-US":"de"}
            href={"/"}
            >
                {locale==="de"?<Image src={"/us.png"} width={15} height={10} className={"absolute ml-3"} alt={"US Flag"}/>:<Image src={"/germany.png"} alt={"Germany Flag"} width={15} height={10} className={"absolute ml-3"}/>}
                <GlobeAltIcon height={23} width={23} color={"rgb(107, 114, 128)"}/>
            </Link>
        </div>
    )
}
