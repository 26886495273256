import {i18n} from "next-i18next";
import {useRouter} from "next/router";
import Link from "next/link";
import {GlobeAltIcon} from "@heroicons/react/24/outline";
import Image from "next/image";


export default function LanguageSwitchDesktop() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const router = useRouter();
    const locale = router.locale;


    return (
        <div className={'absolute top-0 w-full max-w-7xl flex flex-col px-12 py-12 flex items-end lg:flex hidden'}>
            <Link className={'w-[30px] h-[30px]'}
                  locale={locale === "de" ? "en-US" : "de"}
                  href={"/"}
            >
                {locale === "de" ?
                    <Image src={"/us.png"} width={19} height={15} className={"absolute ml-5"} alt={"US Flag"}/> :
                    <Image src={"/germany.png"} width={19} height={10} className={"absolute ml-5"}
                           alt={"Germany Flag"}/>}
                <GlobeAltIcon height={30} width={30} className={"text-gray-400"}/>
            </Link>
        </div>
    )
}
