import {useAuth} from "../context/AuthContext";
import UnprotectedDefaultLayout from "./UnprotectedDefaultLayout";
import Signup from "../pages/signup";
import DefaultSidebar from "./DefaultSidebar";
import {useEffect, useState} from "react";
import Footer from "./Footer";
import LanguageSwitchDesktop from "./LanguageSwitchDesktop";

function DefaultLayout({children}) {

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            // only execute all the code below in client side
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const size = useWindowSize();
    return (
        <>
            {/*Return Desktop Layout if bigger than 1024, else mobile Layout*/}
            {size.width >= 1024 ?
                (<div className="flex lg:flex-col min-h-screen">
                    <div className="flex h-screen">

                        <DefaultSidebar/>
                        <main className="min-h-full w-full overflow-scroll flex flex-col justify-between">

                            {children}
                            <Footer/>
                        </main>
                    </div>
                </div>)
                :
                (<div className="flex h-screen">
                    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                        <DefaultSidebar/>
                        <main className="min-h-full w-full overflow-scroll pb-20">
                            {children}
                            <Footer/>
                        </main>
                    </div>
                </div>)
            }
        </>
    )
}

export function FullPageLayout({children}) {
    const user = useAuth().user;
    const loadingUser = useAuth().loading

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            // only execute all the code below in client side
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const size = useWindowSize();
    return (
        <>
            {/*Return Desktop Layout if bigger than 1024, else mobile Layout*/}
            {size.width >= 1024 ?
                (<div className="flex lg:flex-col min-h-screen">
                    <div className="flex h-screen">
                        <DefaultSidebar/>
                        <main className="min-h-full w-full overflow-scroll flex flex-col justify-between">
                            {children}
                            <Footer/>
                        </main>
                    </div>
                </div>)
                :
                (<div className="flex h-screen">
                    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                        <DefaultSidebar/>
                        <main className="min-h-full w-full overflow-scroll">
                            {children}
                        </main>

                    </div>
                </div>)
            }
        </>
    )
}

export default DefaultLayout;

