import {
    ArrowTrendingUpIcon,
    Bars3Icon,
    HomeIcon,
    XMarkIcon,
    ComputerDesktopIcon,
    FolderOpenIcon,
    DocumentTextIcon,
    ChatBubbleBottomCenterTextIcon,
    DevicePhoneMobileIcon,
    ShoppingCartIcon
} from '@heroicons/react/24/outline'
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import Link from "next/link";
import Image from "next/image";
import {useRouter} from "next/router";
import {useAuth} from "../context/AuthContext";
import LanguageSwitchMobile from "./LanguageSwitchMobile";


const navigation = {
    en: [
        {name: 'Home', href: '/', icon: HomeIcon},
        {name: 'Ad Text', href: '/create-ad', icon: ArrowTrendingUpIcon},
        {name: 'Website Text', href: '/create-website-text', icon: ComputerDesktopIcon},
        {name: 'E-Commerce', href: '/ecommerce', icon: ShoppingCartIcon},
        {name: 'Long Form Content', href: '/blogpost-wizard', icon: DocumentTextIcon},
        {name: 'Rephrase', href: '/rephrase', icon: ChatBubbleBottomCenterTextIcon},
        {name: 'Social Media', href: '/socialmedia', icon: DevicePhoneMobileIcon},
        {name: 'Saved Content', href: '/my-content', icon: FolderOpenIcon},
    ],
    de: [
        {name: 'Home', href: '/', icon: HomeIcon},
        {name: 'Werbetexte', href: '/create-ad', icon: ArrowTrendingUpIcon},
        {name: 'Webseitentexte', href: '/create-website-text', icon: ComputerDesktopIcon},
        {name: 'E-Commerce', href: '/ecommerce', icon: ShoppingCartIcon},
        {name: 'Lange Artikel', href: '/blogpost-wizard', icon: DocumentTextIcon},
        {name: 'Umformulierung', href: '/rephrase', icon: ChatBubbleBottomCenterTextIcon},
        {name: 'Social Media', href: '/socialmedia', icon: DevicePhoneMobileIcon},
        {name: 'Gespeicherter Content', href: '/my-content', icon: FolderOpenIcon},
    ]
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DefaultSidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const router = useRouter();
    const locale = router.locale;
    const user = useAuth();

    return (
        <>
            {/*Desktop sidebar*/}
            <div className="hidden lg:flex lg:flex-shrink-0">
                <div className="flex w-64 flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex min-h-0 flex-1 flex-col bg-gray-50 border-right">
                        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                            <div className="flex flex-shrink-0 items-center px-4">
                                <Image
                                    onClick={() => router.push("/")}
                                    width={150}
                                    height={50}
                                    fill={false}
                                    src="/rescribe_logo.png"
                                    alt="Your Company"
                                />
                            </div>
                            <nav className="mt-5 flex-1" aria-label="Sidebar">
                                <div className="space-y-1 px-2">
                                    {locale === "de" ? navigation.de.map((item) => (
                                        <Link
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                                router.pathname === item.href
                                                    ? 'glass-level-1 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    )) : navigation.en.map((item) => (
                                        <Link
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                                router.pathname === item.href
                                                    ? 'glass-level-1 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </nav>
                        </div>
                        <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                            <Link href="/settings" className="group block w-full flex-shrink-0">
                                <div className="flex items-center">
                                    <div>
                                        <Image
                                            className="inline-block h-9 w-9 rounded-full"
                                            src="/profile-placeholder.svg"
                                            alt="Profile"
                                            width={30}
                                            height={30}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{user.user.firstname + " " + user.user.lastname}</p>
                                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{locale==="de"?"Profil anzeigen":"View profile"}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/*Mobile Menu*/}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                    <div className="flex flex-shrink-0 items-center px-4">
                                        <Image
                                            onClick={() => router.push("/")}
                                            width={150}
                                            height={50}
                                            fill={false}
                                            src="/rescribe_logo.png"
                                            alt="Your Company"
                                        />
                                    </div>
                                    <nav aria-label="Sidebar" className="mt-5">
                                        <div className="space-y-1 px-2">
                                            {locale == "de" ? navigation.de.map((item) => (
                                                <Link
                                                    onClick={() => setSidebarOpen(false)}
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        router.pathname === item.href
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-4 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            )) : navigation.en.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    href={item.href}
                                                    onClick={() => setSidebarOpen(false)}
                                                    className={classNames(
                                                        router.pathname === item.href
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-4 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                                    <Link href="/settings" className="group block flex-shrink-0" onClick={() => setSidebarOpen(false)}>
                                        <div className="flex items-center">
                                            <div>
                                                <Image
                                                    className="inline-block h-10 w-10 rounded-full"
                                                    src="/profile-placeholder.svg"
                                                    alt=""
                                                    width={30}
                                                    height={30}
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                                                    {user.user.firstname + " " + user.user.lastname}
                                                </p>
                                                <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{locale=="de"?"Dein Profil":"View Profile"}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="lg:hidden">
                <div className="flex items-center justify-between border-b border-gray-200 px-4 py-1.5">
                    <div>
                        <Image
                            onClick={() => router.push("/")}
                            width={150}
                            height={50}
                            fill={false}
                            src="/rescribe_logo.png"
                            alt="Your Company"
                        />
                    </div>

                    <div className={"flex items-center"}>
                        <LanguageSwitchMobile/>
                        <button
                            type="button"
                            className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

